import React, { useState } from 'react';
import {
  useGetItemTransfersQuery,
} from '../../../redux/vmsApi';
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  IconButton,
  Chip,
  Dialog,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  DialogContent,
  styled,
  DialogTitle,
  Grid
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatCurrency } from '../../../utils/currencyUtil';
import { AttachMoney, Description, ShoppingCart } from '@mui/icons-material';


const ItemTransfersList: React.FC = () => {
  const GradientDialogTitle = styled(DialogTitle)(({ theme }) => ({
    background: 'linear-gradient(45deg, #1976d2, #2196f3)',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    textAlign: 'center',
  }));

  const StyledTable = styled(Table)(({ theme }) => ({
    marginTop: theme.spacing(2),
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: theme.shadows[3],
  }));


  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.common.white,
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  }));

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const calculateVAT = (item: any) => {
    if (!item.VAT) return 0;
    const discountedPrice = item.rate - (item.discountPerItem || 0);
    const vatAmount = discountedPrice * item.quantity.quantityValue * 0.15;
    return vatAmount;
  };
  const [filters, setFilters] = useState({
    title: '',
    transferredBy: '',
    purchaseOrderNumber: '',
    quotationNumber: '',
    grvNumber:'',
    currency: '',
    fromDate: '2025-01-01',
    toDate: getCurrentDate(),
    isService: false,
    sourceLocationType: '',
    destinationLocationType: ''
  });

  const [selectedTransfer, setSelectedTransfer] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentOpenRow, setCurrentOpenRow] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [apiParams, setApiParams] = useState({ ...filters, skip: 0, limit: 10 });

  const { data: itemTransfers, isLoading, isError } = useGetItemTransfersQuery(apiParams);

  const handleToggleDropdown = (rowId: string) => {
    setCurrentOpenRow((prev) => (prev === rowId ? null : rowId));
  };

  const handleViewDetails = (transfer: any) => {
    setSelectedTransfer(transfer);
    setOpenDialog(true);
  };

  const handleFilterChange = (field: string, value: string | boolean) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleApplyFilters = () => {
    setApiParams({
      ...filters,
      skip: 0,
      limit: pageSize,
    });
    setPage(0);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setApiParams((prevParams) => ({
      ...prevParams,
      skip: newPage * pageSize,
    }));
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    setPage(0);
    setApiParams((prevParams) => ({
      ...prevParams,
      limit: newSize,
      skip: 0,
    }));
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedTransfer(null);
  };

  const getLocationName = (location: any) => {
    if (location?.unit?.name) return `Unit: ${location.unit.name}`;
    if (location?.project?.name) return `Project: ${location.project.name}`;
    if (location?.development?.name) return `Development: ${location.development.name}`;
    if (location?.inventoryLocation?.locationCode) return `Inventory: ${location.inventoryLocation.locationCode}`;
    if (location?.supplier?.name) return `Supplier: ${location.supplier.name}`;
    return 'N/A';
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching item transfers.</div>;
  }

  return (
    <React.Fragment>
     <Box sx={{ padding: 3, borderRadius: 2, marginBottom: 3, backgroundColor: '#f9f9f9', boxShadow: 3 }}>
  <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
    Item Transfers
  </Typography>
  <Box display="flex" flexWrap="wrap" gap={2} alignItems="center">
   
    <TextField
      label="Title"
      variant="outlined"
      size="small"
      fullWidth
      sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
      value={filters.title}
      onChange={(e) => handleFilterChange('title', e.target.value)}
    />
    <TextField
      label="Transferred By"
      variant="outlined"
      size="small"
      fullWidth
      sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
      value={filters.transferredBy}
      onChange={(e) => handleFilterChange('transferredBy', e.target.value)}
    />
    <TextField
      label="PO Number"
      variant="outlined"
      size="small"
      fullWidth
      sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
      value={filters.purchaseOrderNumber}
      onChange={(e) => handleFilterChange('purchaseOrderNumber', e.target.value)}
    />
    
   
    <TextField
      label="Quotation Number"
      variant="outlined"
      size="small"
      fullWidth
      sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
      value={filters.quotationNumber}
      onChange={(e) => handleFilterChange('quotationNumber', e.target.value)}
    />
    <TextField
      label="Currency"
      variant="outlined"
      size="small"
      fullWidth
      sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
      value={filters.currency}
      onChange={(e) => handleFilterChange('currency', e.target.value)}
    />
    <TextField
      label="GRV Number"
      variant="outlined"
      size="small"
      fullWidth
      sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
      value={filters.grvNumber}
      onChange={(e) => handleFilterChange('grvNumber', e.target.value)}
    />
    
    
    
    <FormControl fullWidth sx={{ maxWidth: 240 }}>
      <Select
        value={filters.sourceLocationType || ''}
        onChange={(e) => handleFilterChange('sourceLocationType', e.target.value)}
        displayEmpty
        size="small"
        sx={{ backgroundColor: '#fff', borderRadius: 1 }}
      >
        <MenuItem value="">All Source Types</MenuItem>
        <MenuItem value="unit">Unit</MenuItem>
        <MenuItem value="project">Project</MenuItem>
        <MenuItem value="development">Development</MenuItem>
        <MenuItem value="inventory">Inventory</MenuItem>
        <MenuItem value="supplier">Supplier</MenuItem>
      </Select>
    </FormControl>
    
    <FormControl fullWidth sx={{ maxWidth: 240 }}>
      <Select
        value={filters.destinationLocationType || ''}
        onChange={(e) => handleFilterChange('destinationLocationType', e.target.value)}
        displayEmpty
        size="small"
        sx={{ backgroundColor: '#fff', borderRadius: 1 }}
      >
        <MenuItem value="">All Destination Types</MenuItem>
        <MenuItem value="unit">Unit</MenuItem>
        <MenuItem value="project">Project</MenuItem>
        <MenuItem value="development">Development</MenuItem>
        <MenuItem value="inventory">Inventory</MenuItem>
        <MenuItem value="supplier">Supplier</MenuItem>
      </Select>
    </FormControl>
    
   
    <TextField
      label="From Date"
      type="date"
      InputLabelProps={{ shrink: true }}
      size="small"
      fullWidth
      sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
      value={filters.fromDate}
      onChange={(e) => handleFilterChange('fromDate', e.target.value)}
    />
    <TextField
      label="To Date"
      type="date"
      InputLabelProps={{ shrink: true }}
      size="small"
      fullWidth
      sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
      value={filters.toDate}
      onChange={(e) => handleFilterChange('toDate', e.target.value)}
    />
    
   
    <FormControl fullWidth sx={{ maxWidth: 240 }}>
      <Select
        value={filters.isService ? 'true' : 'false'}
        onChange={(e) => handleFilterChange('isService', e.target.value === 'true')}
        size="small"
        sx={{ backgroundColor: '#fff', borderRadius: 1 }}
      >
        <MenuItem value="true">Service</MenuItem>
        <MenuItem value="false">Non-Service</MenuItem>
      </Select>
    </FormControl>
  </Box>
  
  <Stack direction="row" spacing={2} mt={2}>
    <Button
      variant="outlined"
      size="large"
      fullWidth
      onClick={handleApplyFilters}
      sx={{
        textTransform: 'none',
        fontWeight: 'bold',
        color: '#1976d2',
        borderColor: '#1976d2',
        '&:hover': {
          backgroundColor: '#e3f2fd',
          borderColor: '#1565c0',
        },
      }}
    >
      Apply Filters
    </Button>
    <Button
      variant="outlined"
      size="large"
      fullWidth
      onClick={() => setFilters({
        title: '',
        transferredBy: '',
        purchaseOrderNumber: '',
        quotationNumber: '',
        currency: '',
        grvNumber: '',
        sourceLocationType: '',
        destinationLocationType: '',
        fromDate: '2025-01-01',
        toDate: getCurrentDate(),
        isService: false
      })}
      sx={{
        textTransform: 'none',
        fontWeight: 'bold',
        color: '#f44336',
        borderColor: '#f44336',
        '&:hover': {
          backgroundColor: '#ffebee',
          borderColor: '#e53935',
        },
      }}
    >
      Clear Filters
    </Button>
  </Stack>
</Box>

      <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#1976d2' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Title</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Transferred By</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>PO Number</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Source</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Destination</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Date</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Type</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Transferred Items</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Transfer Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemTransfers && itemTransfers.length > 0 ? (
              itemTransfers.map((transfer: any, index: number) => (
                <TableRow key={transfer.id} sx={{ backgroundColor: index % 2 === 0 ? '#f0f4f7' : 'white' }}>
                  <TableCell>{transfer.title || 'N/A'}</TableCell>
                  <TableCell>{transfer.transferredBy.name || 'N/A'}</TableCell>
                  <TableCell>{transfer.purchaseOrderNumber || 'N/A'}</TableCell>
                  <TableCell>{getLocationName(transfer.sourceLocation)}</TableCell>
                  <TableCell>{getLocationName(transfer.destinationLocation)}</TableCell>
                  <TableCell>
                    {new Date(transfer.transferredDate).toLocaleDateString('en-US', {
                      month: 'short', day: 'numeric', year: 'numeric'
                    })}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={transfer.isService ? 'Service' : 'Goods'}
                      color={transfer.isService ? 'primary' : 'secondary'}
                    />
                  </TableCell>
                  
                  <TableCell align="right">
                    <IconButton onClick={() => handleViewDetails(transfer)}>
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  </TableCell>

                  <TableCell>
                    {formatCurrency(transfer.grandTotal, transfer.currency || 'usd')}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">No matching transfers found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <Button
          onClick={() => handlePageChange(Math.max(page - 1, 0))}
          disabled={page === 0}
          variant="contained"
          sx={{ marginRight: 2 }}
        >
          Previous
        </Button>
        <Typography variant="body1">Page {page + 1}</Typography>
        <Button
          onClick={() => handlePageChange(page + 1)}
          disabled={itemTransfers?.length === undefined || itemTransfers.length < pageSize}
          variant="contained"
          sx={{ marginLeft: 2 }}
        >
          Next
        </Button>
      </Box>

      <Box mt={2} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body2" sx={{ marginRight: 2 }}>Rows per page:</Typography>
        <FormControl variant="outlined" size="small">
          <Select
            value={pageSize}
            onChange={handlePageSizeChange}
            sx={{ backgroundColor: '#f0f4f7' }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </FormControl>
      </Box>

     
      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="md">
        <GradientDialogTitle>
          Transfer Details - {selectedTransfer?.title}
        </GradientDialogTitle>
        <DialogContent>
          <Box>
          
            <Box sx={{
              mt: 2,
              p: 3,
              backgroundColor: '#f9f9f9',
              borderRadius: 2,
              boxShadow: 1
            }}>
              <Typography variant="h6" gutterBottom sx={{
                color: 'primary.main',
                fontWeight: 'bold',
                mb: 3
              }}>
                Transfer Information
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="subtitle1" sx={{
                      minWidth: '140px',
                      fontWeight: 'bold',
                      color: 'text.secondary'
                    }}>
                      Transfer Title:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                      {selectedTransfer?.title || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="subtitle1" sx={{
                      minWidth: '140px',
                      fontWeight: 'bold',
                      color: 'text.secondary'
                    }}>
                      Transferred By:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                      {selectedTransfer?.transferredBy?.name || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="subtitle1" sx={{
                      minWidth: '140px',
                      fontWeight: 'bold',
                      color: 'text.secondary'
                    }}>
                      Date:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                      {selectedTransfer?.transferredDate ?
                        new Date(selectedTransfer.transferredDate).toLocaleString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        }) : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="subtitle1" sx={{
                      minWidth: '140px',
                      fontWeight: 'bold',
                      color: 'text.secondary'
                    }}>
                      Reason:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                      {selectedTransfer?.reasonForTransfer || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="subtitle1" sx={{
                      minWidth: '140px',
                      fontWeight: 'bold',
                      color: 'text.secondary'
                    }}>
                      Source:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                      {selectedTransfer?.sourceLocation ? getLocationName(selectedTransfer.sourceLocation) : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="subtitle1" sx={{
                      minWidth: '140px',
                      fontWeight: 'bold',
                      color: 'text.secondary'
                    }}>
                      Destination:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                      {selectedTransfer?.destinationLocation ? getLocationName(selectedTransfer.destinationLocation) : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Typography variant="h6" gutterBottom sx={{ mt: 4, color: 'primary.main' }}>
              <Description sx={{ verticalAlign: 'middle', mr: 1 }} />
              Transfer Items
            </Typography>

            {selectedTransfer?.items?.length > 0 ? (
              <StyledTable>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>
                      <Description sx={{ verticalAlign: 'middle', mr: 1 }} />
                      Description
                    </StyledTableCell>
                    <StyledTableCell>
                      Item Code
                    </StyledTableCell>
                    <StyledTableCell>
                      <ShoppingCart sx={{ verticalAlign: 'middle', mr: 1 }} />
                      Quantity
                    </StyledTableCell>
                    <StyledTableCell>Unit Cost</StyledTableCell>
                    <StyledTableCell>Item Discount</StyledTableCell>
                    <StyledTableCell>VAT</StyledTableCell>
                    <StyledTableCell>
                      <AttachMoney sx={{ verticalAlign: 'middle', mr: 1 }} />
                      Total Cost
                    </StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {selectedTransfer.items.map((item: any, index: number) => (
                    <StyledTableRow key={index}>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.itemCode}</TableCell>
                      <TableCell>
                        {item.quantity.quantityValue} {item.quantity.quantityUnit}
                      </TableCell>
                      <TableCell>{formatCurrency(item.rate, selectedTransfer.currency)}</TableCell>
                      <TableCell>{formatCurrency(item.discountPerItem, selectedTransfer.currency)}</TableCell>
                      <TableCell>{formatCurrency(calculateVAT(item), selectedTransfer.currency)}</TableCell>
                      <TableCell>{formatCurrency(item.totalCostPerItem, selectedTransfer.currency)}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            ) : (
              <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                No items in this transfer.
              </Typography>
            )}

            {selectedTransfer?.items?.length > 0 && (
              <Box
                mt={4}
                p={2}
                sx={{
                  backgroundColor: '#f5f5f5',
                  borderRadius: 1,
                  float: 'right',
                  minWidth: '300px', 
                }}
              >
                {[
                  { label: 'Total Before VAT', value: selectedTransfer.totalb4VAT },
                  { label: 'Total VAT', value: selectedTransfer.totalVAT },
                  { label: 'Total Discount', value: selectedTransfer.totalDiscount },
                  { label: 'Grand Total', value: selectedTransfer.grandTotal },
                ].map((item, index) => (
                  <Box
                    key={index}
                    sx={{ display: 'flex', justifyContent: 'space-between', mt: index > 0 ? 1 : 0 }}
                  >
                    <Typography variant={index === 3 ? 'h6' : 'subtitle1'} sx={{ color: 'blue' }}>
                      <strong>{item.label}:</strong>
                    </Typography>
                    <Typography variant={index === 3 ? 'h6' : 'subtitle1'} sx={{ color: 'red', fontWeight: 'bold' }}>
                      {formatCurrency(item.value, selectedTransfer.currency)}
                    </Typography>
                  </Box>
                ))}
              </Box>

            )}

          <Box sx={{ clear: 'both' }}></Box>
          </Box>

          <Box
              mt={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 0
              }}
            >
              <Button
                onClick={handleDialogClose}
                variant="contained"
                color="primary"
                sx={{ borderRadius: '20px' }}
              >
                Close
              </Button>
            </Box>

        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
};

export default ItemTransfersList;

