import React, {useState} from 'react';
import PageWithHeader from "../common/PageWithHeader";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import {KnowledgeBaseEntry, useGetKnowledgeBaseQuery} from "../../redux/vmsApi";
import {Download, Visibility} from '@mui/icons-material';
import {styled} from "@mui/material/styles";
import config from "../../config";

const StyledCard = styled(Card)(({theme}) => ({
  display: 'flex',
  backgroundColor: '#1976d2',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: '0.3s',
  borderRadius: '30px',
  '&:hover': {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)',
  },
}));

const StyledTypography = styled(Typography)({
  textAlign: 'center', color: '#fff',
});

const KnowledgeBase: React.FC = () => {
  const {data: knowledgeBaseEntries} = useGetKnowledgeBaseQuery();
  const [openDialog, setOpenDialog] = useState<string | null>(null);

  const handleDialogClose = () => setOpenDialog(null);

  return (<PageWithHeader>
      <Paper elevation={2} sx={{margin: 10, padding: 5, borderRadius: '15px'}}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant='h1' gutterBottom sx={{fontWeight: 'bold', color: '#123456'}}>
              Knowledge Base
            </Typography>
          </Grid>
        </Grid>
        <Typography variant='body1' gutterBottom>
          Welcome to the Knowledge Base. Here, you'll find a collection of internal policies, guidelines, and important
          documents designed to help you navigate your role more effectively. Whether you’re looking for company
          procedures, compliance requirements, or best practices, this resource hub provides easy access to all the
          information you need.
        </Typography>
        <Grid container spacing={3}>
          {knowledgeBaseEntries ? knowledgeBaseEntries.map((entry: KnowledgeBaseEntry) => (
            <Grid item xs={12} md={6} lg={4} key={entry.id}>
              <StyledCard>
                <CardContent sx={{width: '100%'}}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width: '100%'}}>
                    <StyledTypography variant="h6" sx={{textAlign: 'left'}}>
                      {entry.documentName}
                    </StyledTypography>
                    <Stack direction={'row'} spacing={2}>
                      <IconButton onClick={() => setOpenDialog(`${config.CDN_URL}/${entry.documentPath}`)}>
                        <Visibility sx={{fontSize: 25, color: '#fff'}}/>
                      </IconButton>
                      <IconButton>
                        <a
                          href={`${config.CDN_URL}/${entry.documentPath}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none', color: 'inherit', display: 'flex' }}
                        >
                          <Download sx={{ fontSize: 25, color: '#fff' }} />
                        </a>
                      </IconButton>
                    </Stack>
                  </Stack>
                </CardContent>
              </StyledCard>
            </Grid>))
            :
            <CircularProgress sx={{margin: 10}}/>
          }
        </Grid>
      </Paper>
      <Dialog open={!!openDialog} onClose={handleDialogClose} fullWidth maxWidth="md">
        <Box p={2}>
          {openDialog && (
            <iframe
              src={openDialog}
              title="File Viewer"
              width="100%"
              height="750px"
              style={{ border: 'none' }}
            />
          )}
        </Box>
      </Dialog>
    </PageWithHeader>)
}

export default KnowledgeBase;