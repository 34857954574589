import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Autocomplete, Button, Card, Grid, IconButton, MenuItem, Stack, TextField, Typography} from '@mui/material';
import {Field, FieldArray, useFormikContext} from 'formik';
import createRequisitionModel from './models/createRequisitionModel';
import {Delete as DeleteIcon} from '@mui/icons-material';
import {
  RequisitionItem,
  useGetBomsByUnitIdQuery,
  useGetDevelopmentsByIdQuery,
  useGetDevelopmentsQuery,
  useGetItemsDropdownQuery,
  useGetProcurementProcessesByIdQuery,
  useGetUnitsByProjectByProjectIdQuery
} from '../../redux/vmsApi';
import DropzoneArea from '../common/DropzoneArea';
import {formatCurrency} from "../../utils/currencyUtil";


const {
  formField: {
    title,
    development,
    developmentName,
    projectName,
    project,
    totalCost,
    documents,
    items: {
      fields: itemFields
    }
  },
} = createRequisitionModel;

interface RouteParams {
  uuid: string;
}

interface CreateRequisitionFormProps {
  procurementProcess: any;
  development: any;
}

let totalPerItem: number;
const CreateRequisitionForm: React.FC = () => {
  const {errors, touched, values, setFieldValue} = useFormikContext<any>();
  const {uuid} = useParams<RouteParams>();
  const [selectedDevelopment, setSelectedDevelopment] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');

  const {data: developments = []} = useGetDevelopmentsQuery();
  const {data: inventoryItems = []} = useGetItemsDropdownQuery();
  const {data: developmentData} = useGetDevelopmentsByIdQuery(
    {id: selectedDevelopment},
    {skip: !selectedDevelopment}
  );
  const {data: boms = []} = useGetBomsByUnitIdQuery({unitId: selectedUnit}, {skip: !selectedUnit});
  const {data: units = [], refetch: refetchUnits} = useGetUnitsByProjectByProjectIdQuery(
    {projectId: selectedProject},
    {skip: !selectedProject}
  );


  const handleDevelopmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const developmentId = event.target.value;
    setSelectedDevelopment(developmentId);
    setFieldValue('development', developmentId);
    setFieldValue('project', '');
    setSelectedProject('');
    setSelectedUnit('');
  };


  const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const unitId = event.target.value;
    setSelectedUnit(unitId);

    const unitObject = units.find(unit => unit.id === unitId);
    const unitName = unitObject ? unitObject.unitName : '';

    const updatedItems = [...values.items];
    updatedItems[index] = {
      ...updatedItems[index],
      unit: unitId,
      unitName: unitName
    };
    setFieldValue('items', updatedItems);
  };


  const projects = selectedDevelopment
    ? developments.find((dev) => dev.id === selectedDevelopment)?.projects || []
    : [];

  const handleProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const projectId = event.target.value;
    const selectedProjectObject = projects.find(project => project.id === projectId);
    setSelectedProject(projectId);
    setFieldValue('project', projectId);
    if (selectedProjectObject) {
      setFieldValue('projectName', selectedProjectObject.name);
    }
  };

  const unitOptions = [
    { value: 'm' },
    { value: 'm²' },
    { value: 'm³' },
    { value: 'kg' },
    { value: 'buckets' },
    { value: 'item'},
    { value: 'unit'},
    { value: 'length'},
    { value: 'bags' },
    { value: 'each' },
    { value: 'pack' },
    { value: 'box' },
    { value: 'litres' },
    { value: 'Tonnes' },
    { value: 'rolls' },
    { value: 'pairs' },
    { value: 'drums'},
    { value: 'hour(s)'}
  ];

  useEffect(() => {
    if (selectedProject) {
      refetchUnits();
    }
  }, [selectedProject, refetchUnits]);

  // const bomItems = boms.flatMap((bom: { sections: any[]; }) =>
  //   bom.sections.flatMap(section =>
  //     section.items.map((item: { itemId: any; description: any; quantity: any; quantityUnit: any; rate: any; totalCostPerItem: any }) => ({
  //       itemId: item.itemId,
  //       description: item.description,
  //       quantityValue: item.quantity || 0,
  //       quantityUnit: item.quantityUnit || '',
  //       rate: item.rate,
  //       totalCostPerItem: item.totalCostPerItem
  //     }))
  //   )
  // );


  const handleAutocompleteChange = (newValue: any | null, index: number) => {
    if (newValue) {
      setFieldValue(`items[${index}].itemCode`, newValue.itemCode);
      setFieldValue(`items[${index}].description`, newValue.description);
      setFieldValue(`items[${index}].quantity.quantityValue`, 0);
      setFieldValue(`items[${index}].quantity.quantityUnit`, newValue.quantityUnit);
      // setFieldValue(`items[${index}].unit`, newValue.unit?.id);
      // setFieldValue(`items[${index}].unitName`, newValue.unit?.name);
      // setFieldValue(`items[${index}].rate`, newValue.itemCost);
      const totalCost = Number(newValue.itemCost) * Number(newValue.quantityValue);
      setFieldValue(`items[${index}].totalCostPerItem`, 0);

    } else {
      setFieldValue(`items[${index}].itemCode`, '');
      setFieldValue(`items[${index}].description`, '');
      setFieldValue(`items[${index}].quantity.quantityValue`, '');
      setFieldValue(`items[${index}].quantity.quantityUnit`, '');
      setFieldValue(`items[${index}].rate`, 0);
    }
  };

  const {data: procurementProcess, isLoading, isError} = useGetProcurementProcessesByIdQuery({id: uuid}, {skip: !uuid});

  useEffect(() => {
    if (procurementProcess) {
      const {requisition} = procurementProcess;
      if (requisition) {
        setFieldValue('title', requisition.title);
        setFieldValue(`development`, requisition.development.id);
        setSelectedDevelopment(requisition.development.id);
        setFieldValue(`project`, requisition.project.id);
        setSelectedProject(requisition.project.id);
        requisition.items.forEach((item, index) => {
          setFieldValue(`items[${index}].itemCode`, item.itemCode);
          setFieldValue(`items[${index}].description`, item.description);
          setFieldValue(`items[${index}].quantity.quantityValue`, item.quantity?.quantityValue);
          setFieldValue(`items[${index}].quantity.quantityUnit`, item.quantity.quantityUnit);
          setFieldValue(`items[${index}].unit`, item.unit?.id);
          setFieldValue(`items[${index}].unitName`, item.unit?.name);
          setFieldValue(`items[${index}].rate`, 0);
          setFieldValue(`items[${index}].totalCostPerItem`, item.totalCostPerItem);
        });
      }

    }
  }, [procurementProcess, setFieldValue]);

  useEffect(() => {
    if (developmentData) {
      setFieldValue('developmentName', developmentData.name);
    }
  }, [developmentData, setFieldValue]);

  useEffect(() => {
    const total = calculateTotal(values.items);

    if (values.totalCost !== total) {
      setFieldValue('totalCost', total);
    }
  }, [values.items, values.totalCost, setFieldValue]);

  function calculateTotal(items: any): number {
    return items.reduce((acc: number, item: any) => {
      const totalPerItem = Number(item.quantity.quantityValue) * Number(item.rate) || 0;
      return acc + totalPerItem;
    }, 0);
  }

  return (
    <Stack width="100%" spacing={5}>


      <Stack width="100%" spacing={4} padding={3}>
        <Card elevation={3} style={{padding: '20px', backgroundColor: '#f9f9f9'}}>
          <Typography variant="h5" gutterBottom style={{fontWeight: 'bold', color: '#123456'}}>
            {procurementProcess?.requisition ? 'Correct Requisition' : 'Raise Requisition'}
          </Typography>
          <br></br>
          <Stack direction="row" spacing={2}>
            <Field
              as={TextField}
              fullWidth
              {...title}
              label="Title"
              sx={{backgroundColor: '#fff', borderRadius: 1}}
              error={touched[title.name] && Boolean(errors[title.name])}
              helperText={touched[title.name] && errors[title.name] ? errors[title.name] : ''}
            />

            <Field
              as={TextField}
              fullWidth
              select

              {...development}
              value={values.development || ''}
              onChange={handleDevelopmentChange}
              sx={{backgroundColor: '#fff', borderRadius: 1}}
              error={touched[development.name] && Boolean(errors[development.name])}
              helperText={touched[development.name] && errors[development.name] ? errors[development.name] : ''}
            >
              {developments.map((development) => (
                <MenuItem key={development.id} value={development.id}>
                  {development.name}
                </MenuItem>
              ))}
            </Field>

            <Field {...developmentName} type="hidden"/>
            <Field {...projectName} type="hidden"/>

            <Field
              as={TextField}
              fullWidth
              select

              {...project}
              value={values.project || ''}
              onChange={handleProjectChange}
              sx={{backgroundColor: '#fff', borderRadius: 1}}
              error={touched[project.name] && Boolean(errors[project.name])}
              helperText={touched[project.name] && errors[project.name] ? errors[project.name] : ''}
            >
              {projects.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Field>

            <Field {...totalCost} type="hidden"/>
          </Stack>

          <FieldArray name="items">
            {({push, remove}) => (
              <>
                {values.items.map((item: RequisitionItem, index: number) => (
                  <Stack direction="row" spacing={2} alignItems="center" key={index} sx={{mt: 2}}>
                    <Field
                      as={TextField}
                      style={{width: '200px'}}
                      select
                      value={values.items[index]?.unit || ''}
                      {...itemFields.unit}
                      onChange={(event: any) => handleUnitChange(event, index)}
                      sx={{backgroundColor: '#fff', borderRadius: 1}}
                      error={touched[`items[${index}].unit.name`] && Boolean(errors[`items[${index}].unit`])}
                      helperText={touched[`items[${index}].unit.name`] && errors[`items[${index}].unit.name`] ? errors[`items[${index}].items.name`] : ''}
                    >
                      {units.map((unit) => (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.unitName}
                        </MenuItem>
                      ))}
                    </Field>

                    <Field {...itemFields.unitName} type="hidden"/>

                    {/* <Autocomplete
                      options={bomItems}
                      getOptionLabel={(option) => option.description}
                      onChange={(event, newValue) => handleAutocompleteChange(newValue, index)}
                      style={{ width: '600px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search BOM Item"
                          variant="outlined"
                          sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                          error={touched.selectedBomItem && Boolean(errors.selectedBomItem)}
                          helperText={touched.selectedBomItem && errors.selectedBomItem ? errors.selectedBomItem : ''}
                        />
                      )}
                    /> */}

                    <Autocomplete
                      options={inventoryItems}
                      getOptionLabel={(option) => option.description || ''}
                      onChange={(event, newValue) => handleAutocompleteChange(newValue, index)}
                      style={{width: '600px'}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Inventory Item"
                          variant="outlined"
                          sx={{backgroundColor: '#fff', borderRadius: 1}}
                        />
                      )}
                    />

                    <Field {...itemFields.itemCode} type="hidden"/>
                    <TextField
                      {...itemFields.description}
                      label="Description"
                      variant="outlined"
                      value={values.items[index]?.description}
                      style={{width: '600px', backgroundColor: '#fff', borderRadius: 1}}


                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`items[${index}].description`, e.target.value)
                      }

                      error={touched[`items[${index}].description.name`] && Boolean(errors[`items[${index}].description.name`])}
                      helperText={touched[`items[${index}].description.name`] && errors[`items[${index}].description.name`] ? errors[`items[${index}].description.name`] : ''}
                    />
                    <TextField
                      {...itemFields.quantity.quantityValue}
                      type="number"
                      label="Quantity"
                      variant="outlined"
                      value={values.items[index]?.quantity?.quantityValue}
                      onChange={(e) => setFieldValue(`items[${index}].quantity.quantityValue`, e.target.value)}
                      sx={{backgroundColor: '#fff', borderRadius: 1}}
                    />

                    <Field
                      as={TextField}

                      select

                      value={values.items[index]?.quantity?.quantityUnit || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`items[${index}].quantity.quantityUnit`, e.target.value)
                      }
                      {...itemFields.quantity.quantityUnit}
                      error={touched[`items[${index}].quantity.quantityUnit.name`] && Boolean(errors[`items[${index}].quantity.quantityUnit.name`])}
                      helperText={touched[`items[${index}].quantity.quantityUnit.name`] && errors[`items[${index}].quantity.quantityUnit.name`] ? errors[`items[${index}].quantity.quantityUnit.name`] : ''}
                      sx={{minWidth: '100px', backgroundColor: '#fff', borderRadius: 1}}

                    >
                      {unitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </Field>

                    <TextField
                      {...itemFields.rate}
                      label="Rate"
                      type="number"
                      variant="outlined"
                      value={values.items[index]?.rate || 0}
                      sx={{backgroundColor: '#fff', borderRadius: 1}}
                      onChange={(e) => {
                        const rate = e.target.value;
                        const quantityValue = values.items[index]?.quantity?.quantityValue || 0;
                        setFieldValue(`items[${index}].rate`, rate);
                        const totalCost = Number(rate) * Number(quantityValue);
                        setFieldValue(`items[${index}].totalCostPerItem`, totalCost);
                      }}
                    />

                    <TextField
                      {...itemFields.totalCostPerItem}
                      label="Total Amount"
                      value={formatCurrency(Number(item.quantity.quantityValue) * Number(item.rate) || 0)}
                      variant="outlined"
                      sx={{backgroundColor: '#fff', borderRadius: 1}}
                      disabled
                    />


                    <IconButton color="error" onClick={() => remove(index)}>
                      <DeleteIcon/>
                    </IconButton>
                  </Stack>
                ))}

                <Button
                  onClick={() => push({description: '', quantity: {quantityValue: '', quantityUnit: ''}, unit: ''})}
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{mt: 2}}
                >
                  Add Requisition Item
                </Button>
              </>
            )}
          </FieldArray>
          <br></br>
          <br></br>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom sx={{color: 'textSecondary'}}>
              Attachments
            </Typography>
            <DropzoneArea
              setFieldValue={setFieldValue}
              fieldName="documents"
              attachments={values.documents || []}
            />
          </Grid>

          <Card
            sx={{
              backgroundColor: '#d1e7dd',
              color: '#0f5132',
              padding: '15px',
              marginTop: '20px',
              textAlign: 'right',
              fontWeight: 'bold',
              fontSize: '1.2em',
            }}
          >
            Total: {formatCurrency(values.totalCost || 0)}
          </Card>
        </Card>
      </Stack>


    </Stack>
  );
};

export default CreateRequisitionForm;
