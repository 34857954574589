
import React, { useState } from 'react';
import {
    useGetItemTransfersQuery,
    useGetPurchaseOrdersQuery,
} from '../../../redux/vmsApi';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Box,
    Button,
    Dialog,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Chip,
    IconButton,
    InputLabel,
    DialogContent,
    styled,
    DialogTitle,
} from '@mui/material';
import { useGetGrvsQuery, useGetProcurementProcessesQuery } from '../../../redux/vmsApi';
import config from "../../../config";
import { formatCurrency } from '../../../utils/currencyUtil';

const GRVList: React.FC = () => {

    const [procurementProcessId, setProcurementProcessId] = useState('');
    const { data: procurementProcesses, refetch } = useGetProcurementProcessesQuery({ procurementProcessId: procurementProcessId });
    const [documentUrl, setDocumentUrl] = useState<string | null>(null);

    const viewGRV = (documentUrl: string) => {
        if (documentUrl) {
            setDocumentUrl(documentUrl);
            setOpenDialog(documentUrl);
        } else {
            console.error('Document URL not found');
            setDocumentUrl(null);
            setOpenDialog(null);
        }
    }
    const handleDialogOpen = async (procurementProcessId: string, documentType: string) => {
        console.log("procurementProcessId", procurementProcessId);

      
        setOpenDialog(null);
        setDocumentUrl(null);

        
        setProcurementProcessId(procurementProcessId);
        const { data: updatedProcurementProcesses } = await refetch();

        if (updatedProcurementProcesses?.[0]) {
            setProcurementProcessId(procurementProcessId);
            const { data: updatedProcurementProcesses } = await refetch();
            if (updatedProcurementProcesses?.[0]) {
                console.log("updatedProcurementProcesses?.[0]", updatedProcurementProcesses?.[0]);
                const documentUrl = `${config.CDN_URL}/procurement/${documentType === 'purchaseOrder'
                    ? updatedProcurementProcesses[0].purchaseOrderDocument?.name
                    : updatedProcurementProcesses[0].requisitionDocument?.name
                    }`;

                if (documentUrl) {
                    setDocumentUrl(documentUrl);
                    setOpenDialog(documentUrl); 
                } else {
                    console.error('Document URL not found');
                    setOpenDialog(null);
                }

                console.log("<<<<----documentUrl----->", documentUrl);
            }



        } else {
            console.error('Procurement process not found');
            setOpenDialog(null);
        }
    };

    const handleDialogClose = () => setOpenDialog(null);

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const calculateVAT = (item: any) => {
        if (!item.VAT) return 0;
        const discountedPrice = item.rate - (item.discountPerItem || 0);
        const vatAmount = discountedPrice * item.quantity.quantityValue * 0.15;
        return vatAmount;
    };
    const [filters, setFilters] = useState({
        title: '',
        transferredBy: '',
        purchaseOrderNumber: '',
        quotationNumber: '',
        grvNumber: '',
        supplier: '',
        currency: '',
        fromDate: '2025-01-01',
        toDate: getCurrentDate(),
        isService: false,
        sourceLocationType: 'supplier',
        destinationLocationType: ''
    });

    const [selectedTransfer, setSelectedTransfer] = useState<any>(null);
    const [openDialog, setOpenDialog] = useState<string | null>(null);
    const [currentOpenRow, setCurrentOpenRow] = useState<string | null>(null);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [apiParams, setApiParams] = useState({ ...filters, skip: 0, limit: 10 });

    const { data: itemTransfers, isLoading, isError } = useGetItemTransfersQuery(apiParams);

    const handleToggleDropdown = (rowId: string) => {
        setCurrentOpenRow((prev) => (prev === rowId ? null : rowId));
    };



    const handleFilterChange = (field: string, value: string | boolean) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [field]: value,
        }));
    };

    const handleApplyFilters = () => {
        setApiParams({
            ...filters,
            skip: 0,
            limit: pageSize,
        });
        setPage(0);
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
        setApiParams((prevParams) => ({
            ...prevParams,
            skip: newPage * pageSize,
        }));
    };

    const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
        setPage(0);
        setApiParams((prevParams) => ({
            ...prevParams,
            limit: newSize,
            skip: 0,
        }));
    };



    const getLocationName = (location: any) => {
        if (location?.unit?.name) return `Unit: ${location.unit.name}`;
        if (location?.project?.name) return `Project: ${location.project.name}`;
        if (location?.development?.name) return `Development: ${location.development.name}`;
        if (location?.inventoryLocation?.locationCode) return `Inventory: ${location.inventoryLocation.locationCode}`;
        if (location?.supplier?.name) return `Supplier: ${location.supplier.name}`;
        return 'N/A';
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error fetching item transfers.</div>;
    }

    return (
        <React.Fragment>
            <Box sx={{ padding: 3, borderRadius: 2, marginBottom: 3, backgroundColor: '#f9f9f9', boxShadow: 3 }}>
                <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Filter
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={2} alignItems="center">
                    
                    <TextField
                        label="Title"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.title}
                        onChange={(e) => handleFilterChange('title', e.target.value)}
                    />
                    <TextField
                        label="Transferred By"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.transferredBy}
                        onChange={(e) => handleFilterChange('transferredBy', e.target.value)}
                    />
                    <TextField
                        label="PO Number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.purchaseOrderNumber}
                        onChange={(e) => handleFilterChange('purchaseOrderNumber', e.target.value)}
                    />

                   
                    <TextField
                        label="Quotation Number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.quotationNumber}
                        onChange={(e) => handleFilterChange('quotationNumber', e.target.value)}
                    />
                    <TextField
                        label="Currency"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.currency}
                        onChange={(e) => handleFilterChange('currency', e.target.value)}
                    />
                    <TextField
                        label="GRV Number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.grvNumber}
                        onChange={(e) => handleFilterChange('grvNumber', e.target.value)}
                    />

                    <TextField
                        label="Supplier Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.supplier}
                        onChange={(e) => handleFilterChange('supplier', e.target.value)}
                    />


                   
                    <FormControl fullWidth sx={{ maxWidth: 240 }}>
                        <Select
                            value={filters.sourceLocationType || ''}
                            onChange={(e) => handleFilterChange('sourceLocationType', e.target.value)}
                            displayEmpty
                            size="small"
                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                        >
                            <MenuItem value="">All Source Types</MenuItem>
                            <MenuItem value="unit">Unit</MenuItem>
                            <MenuItem value="project">Project</MenuItem>
                            <MenuItem value="development">Development</MenuItem>
                            <MenuItem value="inventory">Inventory</MenuItem>
                            <MenuItem value="supplier">Supplier</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ maxWidth: 240 }}>
                        <Select
                            value={filters.destinationLocationType || ''}
                            onChange={(e) => handleFilterChange('destinationLocationType', e.target.value)}
                            displayEmpty
                            size="small"
                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                        >
                            <MenuItem value="">All Destination Types</MenuItem>
                            <MenuItem value="unit">Unit</MenuItem>
                            <MenuItem value="project">Project</MenuItem>
                            <MenuItem value="development">Development</MenuItem>
                            <MenuItem value="inventory">Inventory</MenuItem>
                            <MenuItem value="supplier">Supplier</MenuItem>
                        </Select>
                    </FormControl>

                    
                    <TextField
                        label="From Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.fromDate}
                        onChange={(e) => handleFilterChange('fromDate', e.target.value)}
                    />
                    <TextField
                        label="To Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.toDate}
                        onChange={(e) => handleFilterChange('toDate', e.target.value)}
                    />

                    
                    <FormControl fullWidth sx={{ maxWidth: 240 }}>
                        <Select
                            value={filters.isService ? 'true' : 'false'}
                            onChange={(e) => handleFilterChange('isService', e.target.value === 'true')}
                            size="small"
                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                        >
                            <MenuItem value="true">Service</MenuItem>
                            <MenuItem value="false">Non-Service</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Stack direction="row" spacing={2} mt={2}>
                    <Button
                        variant="outlined"
                        size="large"
                        fullWidth
                        onClick={handleApplyFilters}
                        sx={{
                            textTransform: 'none',
                            fontWeight: 'bold',
                            color: '#1976d2',
                            borderColor: '#1976d2',
                            '&:hover': {
                                backgroundColor: '#e3f2fd',
                                borderColor: '#1565c0',
                            },
                        }}
                    >
                        Apply Filters
                    </Button>
                    <Button
                        variant="outlined"
                        size="large"
                        fullWidth
                        onClick={() => setFilters({
                            title: '',
                            transferredBy: '',
                            purchaseOrderNumber: '',
                            quotationNumber: '',
                            currency: '',
                            grvNumber: '',
                            sourceLocationType: '',
                            supplier:'',
                            destinationLocationType: '',
                            fromDate: '2025-01-01',
                            toDate: getCurrentDate(),
                            isService: false
                        })}
                        sx={{
                            textTransform: 'none',
                            fontWeight: 'bold',
                            color: '#f44336',
                            borderColor: '#f44336',
                            '&:hover': {
                                backgroundColor: '#ffebee',
                                borderColor: '#e53935',
                            },
                        }}
                    >
                        Clear Filters
                    </Button>
                </Stack>
            </Box>

            <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#1976d2' }}>
                        <TableRow>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Title</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Received By</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>PO Number</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Supplier</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Destination</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Date</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Type</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Requisition Document</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Purchase Order Document</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>GRV Document</TableCell>
                            {/* <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">GRV Document</TableCell> */}
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>GRV Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {itemTransfers && itemTransfers.length > 0 ? (
                            itemTransfers.map((transfer: any, index: number) => (
                                <TableRow key={transfer.id} sx={{ backgroundColor: index % 2 === 0 ? '#f0f4f7' : 'white' }}>
                                    <TableCell>{transfer.title || 'N/A'}</TableCell>
                                    <TableCell>{transfer.transferredBy.name || 'N/A'}</TableCell>
                                    <TableCell>{transfer.purchaseOrderNumber || 'N/A'}</TableCell>
                                    <TableCell>{getLocationName(transfer.sourceLocation)}</TableCell>
                                    <TableCell>{getLocationName(transfer.destinationLocation)}</TableCell>
                                    <TableCell>
                                        {new Date(transfer.transferredDate).toLocaleDateString('en-US', {
                                            month: 'short', day: 'numeric', year: 'numeric'
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            label={transfer.isService ? 'Service' : 'Goods'}
                                            color={transfer.isService ? 'primary' : 'secondary'}
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <IconButton
                                            onClick={() => handleDialogOpen(transfer.procurementProcessId || '', 'requisition')}
                                            sx={{ color: '#1976d2' }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </TableCell>

                                    <TableCell>
                                        <IconButton
                                            onClick={() => handleDialogOpen(transfer.procurementProcessId || '', 'purchaseOrder')}
                                            sx={{ color: '#1976d2' }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => viewGRV(transfer.grvDocument.url)}
                                            sx={{ color: '#1976d2' }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </TableCell>

                                    <TableCell>
                                        {formatCurrency(transfer.grandTotal, transfer.currency || 'usd')}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={9} align="center">No matching transfers found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

           
            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <Button
                    onClick={() => handlePageChange(Math.max(page - 1, 0))}
                    disabled={page === 0}
                    variant="contained"
                    sx={{ marginRight: 2 }}
                >
                    Previous
                </Button>
                <Typography variant="body1">Page {page + 1}</Typography>
                <Button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={itemTransfers?.length === undefined || itemTransfers.length < pageSize}
                    variant="contained"
                    sx={{ marginLeft: 2 }}
                >
                    Next
                </Button>
            </Box>

            <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body2" sx={{ marginRight: 2 }}>Rows per page:</Typography>
                <FormControl variant="outlined" size="small">
                    <Select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        sx={{ backgroundColor: '#f0f4f7' }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                </FormControl>
            </Box>


            <Dialog open={!!openDialog} onClose={handleDialogClose} fullWidth maxWidth="md">
                <Box p={2}>
                    {openDialog && (
                        <iframe
                            src={openDialog}
                            title="Document Viewer"
                            width="100%"
                            height="500px"
                            style={{ border: 'none' }}
                        />
                    )}
                    <Box textAlign="right" mt={2}>
                        <Button onClick={handleDialogClose} variant="contained" color="primary">
                            Close
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>
    );
};


export default GRVList;
