import React, { useState } from 'react';
import {
  ItemLocation,
  useGetItemLocationsQuery,
} from '../../../redux/vmsApi';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { formatCurrency } from "../../../utils/currencyUtil";

const ItemLocationsList: React.FC = () => {
  const { data: itemLocations, isLoading, isError } = useGetItemLocationsQuery({});
  const [selectedItemLocation, setSelectedItemLocation] = useState<ItemLocation | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEditClick = (itemLocation: ItemLocation) => {
    setSelectedItemLocation(itemLocation);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedItemLocation(null);
  };




  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching itemLocations.</div>;
  }

  return (
    <Box p={10}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        Item Stock Levels in Particular locations
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Code</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Allocation</TableCell>
              <TableCell>Supplier</TableCell>
              <TableCell>Purchase Order</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Cost</TableCell>
              <TableCell>Unit Discount</TableCell>
              <TableCell>VAT</TableCell>
              <TableCell>Total Value</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {itemLocations?.map((itemLocation: any) => (
              <TableRow key={itemLocation.id}>
                <TableCell>{itemLocation.itemCode},{itemLocation.itemDescription}</TableCell>
                <TableCell>{itemLocation.locationCode},{itemLocation.locationDescription}</TableCell>
                <TableCell>{itemLocation.developmentAllocation?.name},{itemLocation.projectAllocation?.name}</TableCell>
                <TableCell>{itemLocation.supplier}</TableCell>
                <TableCell>{itemLocation.purchaseOrderNumber}</TableCell>
                <TableCell>{itemLocation.stock}</TableCell>
                <TableCell>{formatCurrency(itemLocation.rate, itemLocation.currency || 'usd')}</TableCell>
                <TableCell>{formatCurrency(itemLocation.itemDiscount, itemLocation.currency || 'usd')}</TableCell>
                <TableCell>
                  {formatCurrency((itemLocation.VAT || 0) * (itemLocation.stock || 0), itemLocation.currency || 'usd')}
                </TableCell>
                <TableCell>{formatCurrency(itemLocation.totalValue, itemLocation.currency || 'usd')}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </Box>
  );
};

export default ItemLocationsList;
