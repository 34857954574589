import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Box,
  Button,
  styled,
} from '@mui/material';

import { Description, Code, ShoppingCart, AttachMoney, Warning } from '@mui/icons-material';
import { PurchaseOrderPartial } from '../../../redux/vmsApi';
import { formatCurrency } from '../../../utils/currencyUtil';


const GradientDialogTitle = styled(DialogTitle)(({ theme }) => ({
  background: 'linear-gradient(45deg, #1976d2, #2196f3)',
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textAlign: 'center',
}));

const StyledTable = styled(Table)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: theme.shadows[3],
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.common.white,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));


const calculateVAT = (item:any) => {
  if (!item.VAT) return 0; 
  const discountedPrice = item.rate - (item.discountPerItem || 0);
  const vatAmount = discountedPrice * item.quantity.quantityValue * 0.15;
  return vatAmount;
};

interface Item {
  description: string;
  itemCode: string;
  quantity: {
    quantityValue: string;
    quantityUnit: string;
  };
  unit: {
    name: string;
  };
  totalCostPerItem: number;
}

interface BackOrderAndReceivedComparisonDialogProps {
  open: boolean;
  onClose: () => void;
  itemsReceived: Item[];
  backOrderItems: Item[];
  selectedPurchaseOrder: PurchaseOrderPartial;
}

const BackOrderAndReceivedComparisonDialog: React.FC<BackOrderAndReceivedComparisonDialogProps> = ({
  open,
  onClose,
  itemsReceived,
  backOrderItems,
  selectedPurchaseOrder
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <GradientDialogTitle>
        Received VS Back Order Items
      </GradientDialogTitle>
      <DialogContent>
        <Box>

          <Typography variant="h6" gutterBottom sx={{ mt: 2, color: 'primary.main' }}>
            <Description sx={{ verticalAlign: 'middle', mr: 1 }} />
            Items Received
          </Typography>
          {selectedPurchaseOrder.itemsReceived && selectedPurchaseOrder.itemsReceived?.length > 0 ? (
            <StyledTable>
              <StyledTableHead>
                <TableRow>
                  <StyledTableCell>
                    <Description sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Description
                  </StyledTableCell>
                  <StyledTableCell>

                    Item Code
                  </StyledTableCell>
                  <StyledTableCell>
                    <ShoppingCart sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell>Unit Cost</StyledTableCell>
                  <StyledTableCell>Item Discount</StyledTableCell>
                  <StyledTableCell>VAT</StyledTableCell>
                  <StyledTableCell>
                    <AttachMoney sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Total Cost
                  </StyledTableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {selectedPurchaseOrder.itemsReceived.map((item, index) => (
                  <StyledTableRow key={index}>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.itemCode}</TableCell>
                  <TableCell>
                    {item.quantity.quantityValue} {item.quantity.quantityUnit}
                  </TableCell>

                  <TableCell>{formatCurrency(item.rate, selectedPurchaseOrder.quotation?.currency || 'usd')}</TableCell>
                  <TableCell>{formatCurrency(item.discountPerItem, selectedPurchaseOrder.quotation?.currency || 'usd')}</TableCell>
                  <TableCell>{formatCurrency( calculateVAT(item), selectedPurchaseOrder.quotation?.currency || 'usd')}</TableCell>
                  <TableCell>{formatCurrency(item?.totalCostPerItem, selectedPurchaseOrder.quotation?.currency || 'usd')}</TableCell>
                </StyledTableRow>
                ))}
              </TableBody>
            </StyledTable>
          ) : (
            <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
              No items received yet.
            </Typography>
          )}


          <Typography variant="h6" gutterBottom sx={{ mt: 4, color: 'error.main' }}>
            <Warning sx={{ verticalAlign: 'middle', mr: 1 }} />
            Backorder Items
          </Typography>
          {selectedPurchaseOrder.backOrderItems && selectedPurchaseOrder.backOrderItems?.length > 0 ? (
            <StyledTable>
              <StyledTableHead>
                <TableRow>
                  <StyledTableCell>
                    <Description sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Description
                  </StyledTableCell>
                  <StyledTableCell>

                    Item Code
                  </StyledTableCell>
                  <StyledTableCell>
                    <ShoppingCart sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell>Unit Cost</StyledTableCell>
                  <StyledTableCell>Item Discount</StyledTableCell>
                  <StyledTableCell>VAT</StyledTableCell>
                  <StyledTableCell>
                    <AttachMoney sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Total Cost
                  </StyledTableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {selectedPurchaseOrder.backOrderItems.map((item, index) => (
                 
                 
                 <StyledTableRow key={index}>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.itemCode}</TableCell>
                    <TableCell>
                      {item.quantity.quantityValue} {item.quantity.quantityUnit}
                    </TableCell>

                    <TableCell>{formatCurrency(item.rate, selectedPurchaseOrder.quotation?.currency || 'usd')}</TableCell>
                    <TableCell>{formatCurrency(item.discountPerItem, selectedPurchaseOrder.quotation?.currency || 'usd')}</TableCell>
                    <TableCell>{formatCurrency( calculateVAT(item), selectedPurchaseOrder.quotation?.currency || 'usd')}</TableCell>
                    <TableCell>{formatCurrency(item?.totalCostPerItem, selectedPurchaseOrder.quotation?.currency || 'usd')}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </StyledTable>
          ) : (
            <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
              No backorder items.
            </Typography>
          )}
        </Box>


        <Box textAlign="right" mt={4}>
          <Button onClick={onClose} variant="contained" color="primary" sx={{ borderRadius: '20px' }}>
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BackOrderAndReceivedComparisonDialog;