const itemLocationFormModel = {
    formId: 'itemLocationForm',
    formField: {
      stock: {
        name: 'stock',
        label: 'Quantity',
        required: true,
        requiredErrorMsg: 'Quantity is required',
      },
      itemCode: {
        name: 'itemCode',
        label: 'Item Code',
        required: true,
        requiredErrorMsg: 'item code is required',
      },
      locationCode: {
        name: 'locationCode',
        label: 'Location',
        required: true,
        requiredErrorMsg: 'Location is required',
      },
      totalValue: {
        name: 'totalValue',
        label: 'Total Value',
        required: true,
        requiredErrorMsg: 'Location is required',
      },
    },
    initialValues: {
        itemCode: '',
        locationCode:'',
        totalValue:'',
        stock:'',
       
    },
  };
  
  export type createItemModelType = {
    itemCode: string;
    value: string;
    stock: string;
    totalValue:string;
  };
  
  export default itemLocationFormModel;
  