const itemTransferFormModel = {
    formId: 'itemTransferForm',
    formField: {
        items: {
            name: 'items',
            label: 'Items',
            required: true,
            requiredErrorMsg: 'At least one item is required',
            fields: {
                itemId: {
                    name: 'itemId',
                    label: 'Item',
                },
                itemCode: {
                    name: 'itemCode',
                    label: 'Item Code',
                },
                description: {
                    name: 'description',
                    label: 'Description',
                },
                rate: {
                    name: 'rate',
                    label: 'Rate',
                },
                totalCostPerItem: {
                    name: 'totalCostPerItem',
                    label: 'Total',
                },
                VAT: {
                    name: 'VAT',
                    label: 'VAT',
                },
                discountPerItem: {
                    name: 'discountPerItem',
                    label: 'Discount per Item',
                },
                quantity: {
                    name: 'quantity',
                    label: 'Quantity',
                    required: true,
                    requiredErrorMsg: 'Quantity is required',
                    quantityValue: {
                        name: 'quantityValue',
                        label: 'Quantity',
                        required: true,
                        requiredErrorMsg: 'Quantity is required',
                    },
                    quantityUnit: {
                        name: 'quantityUnit',
                        label: 'Unit',
                        required: true,
                        requiredErrorMsg: 'Unit is required',
                    }
                },
                
            },
        },

        totalVAT: {
            name: 'Total discount',
            label: 'discount',
            required: true,
            requiredErrorMsg: 'Discount is required',
        },
        totalDiscount: {
            name: 'Total discount',
            label: 'discount',
            required: true,
            requiredErrorMsg: 'Discount is required',
        },
        totalb4VAT: {
            name: 'Total discount',
            label: 'discount',
            required: true,
            requiredErrorMsg: 'Discount is required',
        },
        grandTotal: {
            name: 'Total discount',
            label: 'discount',
            required: true,
            requiredErrorMsg: 'Discount is required',
        },
        currency: {
            name: 'currency',
        },
        quotationNumber: {
            name: 'quotationNumber',
          
        },
        purchaseOrderNumber: {
            name: 'purchaseOrderNumber',
           
        },
        procurementProcessId: {
            name: 'procurementProcessId',          
        },
        title: {
            name: 'title',
            label: 'Order Title',
        },

        isService: {
            name: 'isService',
            label: 'Select Type',
            requiredErrorMsg: 'Type is required'
        },
        grvNumber: {
            name: 'grvNumber',
            label: 'Grv Number',
            required: true,
            requiredErrorMsg: 'Grv Number is required',
        },
        transferredDate: {
            name: 'transferredDate',
            label: 'TranferredDate',
            required: true,
            requiredErrorMsg: 'Transferred Date is required',
        },
        reasonForTransfer: {
            name: 'reasonForTransfer',
            label: 'Reason For Transfer',
            required: true,
            requiredErrorMsg: 'Reason For Transfer is required',
        },
        sourceLocationType: {
            name: 'sourceLocationType',
            label: 'Source Location Type',
            required: true,
            requiredErrorMsg: 'Source Location Type is required',
        },

        destinationLocationType: {
            name: 'destinationLocationType',
            label: 'Destination Location Type',
            required: true,
            requiredErrorMsg: 'Destination Location Type is required',
        },
        sourceLocation: {
            name: 'sourceLocation',
            label: 'SourceLocation',
            required: true,
            requiredErrorMsg: 'At least one item is required',
            fields: {
                development: {
                    name: 'development',
                    label: 'development',
                },
                project: {
                    name: 'project',
                    label: 'Project',


                },
                unit: {
                    name: 'unit',
                    label: 'Unit',
                },
                supplierId: {
                    name: 'supplierId',
                    label: 'Supplier',
                    required: true,

                },
                supplierName: {
                    name: 'supplierName',
                    label: 'Supplier',
                    required: true,

                },
                inventoryLocation: {
                    name: 'Inventory location',
                    label: 'Inventory Location',
                    required: true,
                },
            },
        },
        destinationLocation: {
            name: 'destinationLocation',
            label: 'DestinationLocation',
            required: true,
            requiredErrorMsg: ' Destination is required',
            fields: {
                development: {
                    name: 'development',
                    label: 'Development',
                },
                project: {
                    name: 'project',
                    label: 'Project',
                    required: true,

                },
                unit: {
                    name: 'unit',
                    label: 'Unit',
                    required: true,

                },
                supplierId: {
                    name: 'supplierId',
                    label: 'Supplier',
                    required: true,

                },
                supplierName: {
                    name: 'supplierName',
                    label: 'Supplier',
                    required: true,

                },
                inventoryLocation: {
                    name: 'location',
                    label: 'Inventory Location',
                    required: true,
                }
            },
        }

    },
    initialValues: {
        items: [{
            itemId: '',
            itemCode: '',
            description: '',
            quantity: {
                quantityValue: 0,
                quantityUnit: ''
            },
            rate: 0,
            totalCostPerItem: 0,
            VAT: false,
            discountPerItem: 0,
           
        }],
        reasonForTransfer: '',
        totalDiscount: 0,
        totalVAT: 0,
        totalb4VAT: 0,
        grandTotal: 0,
        currency: '',
        quotationNumber: '',
        purchaseOrderNumber:'',
        procurementProcessId:'',
        isService:false,
        grvNumber: '',
        sourceLocationType: '',
        destinationLocationType: '',
        sourceLocation: {
            development: { id: '', name: '' },
            project: { id: '', name: '' },
            unit: { id: '', name: '' },
            supplier: { id: '', name: '' },
            inventoryLocation: { id: '', locationCode: '', description: '', developmentId: '' }
        },
        destinationLocation: {
            development: { id: '', name: '' },
            project: { id: '', name: '' },
            unit: { id: '', name: '' },
            supplier: { id: '', name: '' },
            inventoryLocation: { id: '', locationCode: '', description: '', developmentId: '', developmentName: '' }
        },

    },
};


export default itemTransferFormModel;
