import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, Divider, FormControl, IconButton, MenuItem, Select, Stack, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Delete as DeleteIcon } from '@mui/icons-material';

import itemTransferFormModel from '../models/createItemTransferModel';
import { useGetDevelopmentsByIdQuery, useGetDevelopmentsQuery, useGetGrvsDropdownQuery, useGetInventoryLocationsQuery, useGetItemsQuery, useGetPurchaseOrdersQuery, useGetUnitsByProjectByProjectIdQuery } from '../../../redux/vmsApi';
import { formatCurrency } from "../../../utils/currencyUtil";
import useDebounce from '../../../hooks/useDebounce';
const {
    formField: {
        items: {
            fields: itemFields
        },
        title,
        purchaseOrderNumber,
        procurementProcessId,
        totalVAT,
        totalb4VAT,
        grandTotal,
        totalDiscount,
        sourceLocationType,
        destinationLocationType,
        isService,
        grvNumber,
        transferredDate,
        reasonForTransfer,
        sourceLocation: {
            fields: locationSourceFields
        },
        destinationLocation: {
            fields: locationDestinationFields
        },
    },
} = itemTransferFormModel;

interface OwnProps {
    editing?: boolean;
}

const CreateItemTransferForm = () => {
    const unitOptions = [
        { value: 'm' },
        { value: 'm²' },
        { value: 'm³' },
        { value: 'kg' },
        { value: 'lt' },
        { value: 'item' },
        { value: 'no unit' },
    ];
    const roundToTwoDecimals = (value: number) => Math.round(value * 100) / 100;
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');



    const { data: retrievedPurchaseOrder, refetch } = useGetPurchaseOrdersQuery(
        { purchaseOrderNumber: purchaseOrderNumber }
    );










    const { data: purchaseOrders } = useGetPurchaseOrdersQuery({});
    const { errors, touched, values, setFieldValue } = useFormikContext<any>();

    const { data: inventoryLocations, isLoading, isError } = useGetInventoryLocationsQuery({});
    const { data: grvs, } = useGetGrvsDropdownQuery();

    const [selectedSourceDevelopment, setSelectedSourceDevelopment] = useState('');
    const [selectedSourceProject, setSelectedSourceProject] = useState('');
    const [selectedSourceUnit, setSelectedSourceUnit] = useState('');
    const [selectedSourceLocation, setSelectedSourceLocation] = useState('');
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState('');


    const { data: sourceDevelopmentData } = useGetDevelopmentsByIdQuery(
        { id: selectedSourceDevelopment },
        { skip: !selectedSourceDevelopment }
    );

    const { data: sourceUnits = [], refetch: refetchSourceUnits } =
        useGetUnitsByProjectByProjectIdQuery(
            { projectId: selectedSourceProject },
            { skip: !selectedSourceProject }
        );



    const [selectedDestinationDevelopment, setSelectedDestinationDevelopment] = useState('');
    const [selectedDestinationProject, setSelectedDestinationProject] = useState('');
    const [selectedDestinationUnit, setSelectedDestinationUnit] = useState('');

    const [selectedDestinationLocation, setSelectedDestinationLocation] = useState('');
    const [selectedDestinationSupplier, setSelectedDestinationSupplier] = useState('');

    const { data: destinationDevelopmentData } = useGetDevelopmentsByIdQuery(
        { id: selectedDestinationDevelopment },
        { skip: !selectedDestinationDevelopment }
    );


    const { data: developments = [] } = useGetDevelopmentsQuery();
    const { data: items = [] } = useGetItemsQuery({});
    const { data: units = [], refetch: refetchUnits } = useGetUnitsByProjectByProjectIdQuery(
        { projectId: selectedSourceProject },
        { skip: !selectedSourceProject }
    );

    const { data: destinationUnits = [], refetch: refetchDestinationUnits } =
        useGetUnitsByProjectByProjectIdQuery(
            { projectId: selectedDestinationProject },
            { skip: !selectedDestinationProject }
        );


    const getProjects = (developmentId: string | undefined) =>
        developments.find((dev) => dev.id === developmentId)?.projects || [];

    const handleSourceDevelopmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const developmentId = event.target.value;
        setSelectedSourceDevelopment(developmentId);
        setFieldValue('sourceLocation.development.id', developmentId);
        setFieldValue('sourceLocation.project.id', '');
        setFieldValue('sourceLocation.unit.id', '');
        setSelectedSourceProject('');
        setSelectedSourceUnit('');
    };

    const handleSourceProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const projectId = event.target.value;
        setSelectedSourceProject(projectId);
        const selectedProjectObject = getProjects(selectedSourceDevelopment).find(project => project.id === projectId);
        if (selectedProjectObject) {
            setFieldValue('sourceLocation.project.name', selectedProjectObject.name);
        }
        setFieldValue('sourceLocation.project.id', projectId);
        setFieldValue('sourceLocation.unit.id', '');
        setSelectedSourceUnit('');
    };

    const handleSourceUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const unitId = event.target.value;
        setSelectedSourceUnit(unitId);
        setFieldValue('sourceLocation.unit.id', unitId);

        const unitObject = sourceUnits.find(unit => unit.id === unitId);
        const unitName = unitObject ? unitObject.unitName : '';
        setFieldValue('sourceLocation.unit.name', unitName);
    };

    const handleSourceLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
        const locationId = event.target.value;
        setSelectedSourceLocation(locationId);

        const locationObject = inventoryLocations?.find(location => location.id === locationId);
        const locationCode = locationObject ? locationObject.locationCode : '';
        const locationDescription = locationObject ? locationObject.description : '';
        const developmentName = locationObject ? locationObject?.development?.name : '';
        const developmentId = locationObject ? locationObject?.development?.id : '';

        setFieldValue('sourceLocation.inventoryLocation.locationId', locationId);
        setFieldValue('sourceLocation.inventoryLocation.locationCode', locationCode);
        setFieldValue('sourceLocation.inventoryLocation.description', locationDescription);
        setFieldValue('sourceLocation.inventoryLocation.developmentId', developmentId);
        setFieldValue('sourceLocation.inventoryLocation.developmentName', developmentName);
    };



    const handleDestinationDevelopmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const developmentId = event.target.value;
        setSelectedDestinationDevelopment(developmentId);
        setFieldValue('destinationLocation.development.id', developmentId);
        setFieldValue('destinationLocation.project.id', '');
        setFieldValue('destinationLocation.unit.id', '');
        setSelectedDestinationProject('');
        setSelectedDestinationUnit('');
    };

    const handleDestinationProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const projectId = event.target.value;
        setSelectedDestinationProject(projectId);

        const selectedProjectObject = getProjects(selectedDestinationDevelopment).find(project => project.id === projectId);
        if (selectedProjectObject) {
            setFieldValue('destinationLocation.project.name', selectedProjectObject.name);
        }
        setFieldValue('destinationLocation.project.id', projectId);
        setFieldValue('destinationLocation.unit.id', '');
        setSelectedDestinationUnit('');
    };

    const handleDestinationUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const unitId = event.target.value;
        setSelectedDestinationUnit(unitId);
        setFieldValue('destinationLocation.unit.id', unitId);
        const unitObject = destinationUnits.find(unit => unit.id === unitId);
        const unitName = unitObject ? unitObject.unitName : '';
        setFieldValue('destinationLocation.unit.name', unitName);
    };


    const handleDestinationLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
        const locationId = event.target.value;
        setSelectedDestinationLocation(locationId);
        const locationObject = inventoryLocations?.find(location => location.id === locationId);
        const locationCode = locationObject ? locationObject.locationCode : '';
        const locationDescription = locationObject ? locationObject.description : '';
        const developmentName = locationObject ? locationObject?.development?.name : '';
        const developmentId = locationObject ? locationObject?.development?.id : '';

        setFieldValue('destinationLocation.inventoryLocation.locationId', locationId);
        setFieldValue('destinationLocation.inventoryLocation.locationCode', locationCode);
        setFieldValue('destinationLocation.inventoryLocation.description', locationDescription);
        setFieldValue('destinationLocation.inventoryLocation.developmentId', developmentId);
        setFieldValue('destinationLocation.inventoryLocation.developmentName', developmentName);
    };

    // const handleDestinationSupplier = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const supplierId = event.target.value;
    //     setSelectedDestinationSupplier(supplierId);

    //     const supplierObject = purchaseOrders?.find(supplier => supplier.id === supplierId);
    //     const supplierName = supplierObject ? supplierObject.name : '';
    //     setFieldValue('destinationLocation.supplier.id', supplierId);
    //     setFieldValue('destinationLocation.supplier.name', supplierName);
    // };


    const handleItemSelectionChange = (newValue: any | null, index: number) => {
        if (newValue) {
            setFieldValue(`items[${index}].itemId`, newValue.id);
            setFieldValue(`items[${index}].itemCode`, newValue.itemCode);
            setFieldValue(`items[${index}].description`, newValue.description);
            setFieldValue(`items[${index}].itemCost`, newValue.itemCost);
            setFieldValue(`items[${index}].quantity.quantityUnit`, newValue.quantityUnit);
        } else {

            setFieldValue(`items[${index}].itemId`, '');
            setFieldValue(`items[${index}].itemCode`, '');
            setFieldValue(`items[${index}].description`, '');
            setFieldValue(`items[${index}].itemCost`, '');
            setFieldValue(`items[${index}].quantity.quantityValue`, '');
            setFieldValue(`items[${index}].quantity.quantityUnit`, '');
        }
    };


    useEffect(() => {
        if (sourceDevelopmentData) {
            setFieldValue('sourceLocation.development.name', sourceDevelopmentData.name);
        }
    }, [sourceDevelopmentData, setFieldValue]);

    useEffect(() => {
        if (destinationDevelopmentData) {
            setFieldValue('destinationLocation.development.name', destinationDevelopmentData.name);
        }
    }, [destinationDevelopmentData, setFieldValue]);

    useEffect(() => {
        if (selectedSourceProject) {
            refetchUnits();
        }
    }, [selectedSourceProject, refetchUnits]);

    useEffect(() => {
        if (selectedDestinationProject) {
            refetchDestinationUnits();
        }
    }, [selectedDestinationProject, refetchDestinationUnits]);


    const calculateTotalWithoutDiscount = (items: any) => {
        return items.reduce((acc: number, item: any) => {
            const baseCost = item.quantity.quantityValue * item.rate;
            const VAT = item.VAT ? 0.15 * item.rate * item.quantity.quantityValue : 0;
            return acc + baseCost + VAT;
        }, 0);
    };


    const calculateTotalWithDiscount = (items: any, globalDiscount: number) => {
        const totalWithoutDiscount = calculateTotalWithoutDiscount(items);
        const totalCostSum = items.reduce((acc: number, item: any) => acc + (item.totalCostPerItem || 0), 0);
        return globalDiscount > 0 ? totalWithoutDiscount - globalDiscount : totalCostSum;
    };

    useEffect(() => {
        if (retrievedPurchaseOrder && retrievedPurchaseOrder[0]?.backOrderItems) {
            const backOrderItems = retrievedPurchaseOrder[0].backOrderItems;
            console.log("retrievedPurchaseOrder[0].title", retrievedPurchaseOrder[0].title);

            const updatedItems = backOrderItems.map(item => ({
                description: item.description,
                itemCode: item.itemCode,
                quantity: {
                    quantityValue: item.quantity?.quantityValue || '',
                    quantityUnit: item.quantity?.quantityUnit || '',
                },
                rate: item.rate,
                totalCostPerItem: (item.quantity?.quantityValue || 0) * (item.rate || 0),
                VAT: false,
                procured: item.procured || false,
                unit: item.unit || { id: '', name: '' },
                discountPerItem: item.discountPerItem || 0,

            }));

            console.log("retrievedPurchaseOrder[0].quotation.supplier.id",retrievedPurchaseOrder[0].quotation.supplier.id);
            console.log("retrievedPurchaseOrder[0].quotation.supplier.name",retrievedPurchaseOrder[0].quotation.supplier.name);
            setFieldValue('items', updatedItems);
            
            setFieldValue('sourceLocation.supplier.id', retrievedPurchaseOrder[0].quotation.supplier.id);
            setFieldValue('sourceLocation.supplier.name', retrievedPurchaseOrder[0].quotation.supplier.name);

            setFieldValue("totalVAT", retrievedPurchaseOrder[0].quotation.totalVAT);
            setFieldValue("totalb4VAT", retrievedPurchaseOrder[0].quotation.totalb4VAT);
            setFieldValue("grandTotal", retrievedPurchaseOrder[0].quotation.totalWithDiscount);
            setFieldValue("totalDiscount", retrievedPurchaseOrder[0].quotation.totalDiscount);
            setFieldValue("currency", retrievedPurchaseOrder[0].quotation.currency);
            setFieldValue("title", retrievedPurchaseOrder[0].title);
            setFieldValue("quotationNumber", retrievedPurchaseOrder[0].quotation.quotationNumber);
            setFieldValue("purchaseOrderNumber", retrievedPurchaseOrder[0].purchaseOrderNumber);
            setFieldValue("procurementProcessId", retrievedPurchaseOrder[0].procurementProcessId);
        }
    }, [retrievedPurchaseOrder, setFieldValue]);


    const debouncedBackorderItems = useDebounce(values.items, 300);

    useEffect(() => {
        if (!debouncedBackorderItems || !Array.isArray(debouncedBackorderItems)) return;

        const totalVAT = debouncedBackorderItems.reduce((acc: number, item: any) => {
            const VAT = item.VAT ? 0.15 * (item.rate - (item.discountPerItem || 0)) * item.quantity.quantityValue : 0;
            return acc + VAT;
        }, 0);

        const totalWithDiscount = debouncedBackorderItems.reduce((acc: number, item: any) => {
            const itemTotal = (item.rate - (item.discountPerItem || 0)) * (item.quantity?.quantityValue || 0);
            return acc + itemTotal;
        }, 0);

        const totalb4VAT = totalWithDiscount ;

        const totalDiscount = debouncedBackorderItems.reduce((acc: number, item: any) => {
            const itemDiscount = (item.discountPerItem || 0) * (item.quantity?.quantityValue || 0);
            return acc + itemDiscount;
        }, 0);
        const grandTotal = totalWithDiscount + totalVAT;
        setFieldValue("totalVAT", roundToTwoDecimals(totalVAT));
        setFieldValue("totalb4VAT", roundToTwoDecimals(totalb4VAT));
        setFieldValue("grandTotal", roundToTwoDecimals(grandTotal));
        setFieldValue("totalDiscount", roundToTwoDecimals(totalDiscount));

    }, [debouncedBackorderItems, setFieldValue]);




    return (
        <Stack spacing={5} width="100%">
            <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
                {'Add'} Item Transfer
            </Typography>


            <Card variant="outlined" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                    Transfer From (Source Location)
                </Typography>
                <Divider style={{ marginBottom: '15px' }} />
                <Stack spacing={2}>




                    <TextField
                        select
                        fullWidth
                        {...sourceLocationType}
                        value={values.sourceLocationType}
                        autoComplete="on"
                        onChange={(event: any) => {
                            setFieldValue('sourceLocationType', event.target.value);
                        }}
                    >
                        <MenuItem value="unit">Unit</MenuItem>
                        <MenuItem value="inventoryLocation">Inventory Location</MenuItem>
                        <MenuItem value="supplier">Supplier</MenuItem>
                    </TextField>

                    {values.sourceLocationType === 'unit' && (
                        <>

                            <TextField

                                {...locationSourceFields.development}
                                select
                                value={selectedSourceDevelopment}
                                onChange={handleSourceDevelopmentChange}
                                fullWidth
                            >
                                {developments.map((dev) => (
                                    <MenuItem key={dev.id} value={dev.id}>
                                        {dev.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                {...locationSourceFields.project}
                                select
                                value={selectedSourceProject}
                                onChange={handleSourceProjectChange}
                                fullWidth
                                disabled={!getProjects(selectedSourceDevelopment).length}
                            >
                                {getProjects(selectedSourceDevelopment).map((proj) => (
                                    <MenuItem key={proj.id} value={proj.id}>
                                        {proj.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <Autocomplete
                                options={units || []}
                                getOptionLabel={(option) => option.unitName}
                                value={units.find((unit) => unit.id === selectedSourceUnit) || null}
                                onChange={(event, newValue) => {
                                    const syntheticEvent = {
                                        target: { value: newValue?.id || '' },
                                    } as React.ChangeEvent<HTMLInputElement>;
                                    handleSourceUnitChange(syntheticEvent);
                                }}
                                disabled={!units.length}
                                style={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Unit"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                        </>
                    )}

                    {values.sourceLocationType === 'inventoryLocation' && (
                        <>


                            <Autocomplete
                                options={inventoryLocations || []}
                                getOptionLabel={(option) => `${option.locationCode}, ${option.description}`}
                                value={inventoryLocations?.find((loc) => loc.id === selectedSourceLocation) || null}
                                onChange={(event, newValue) => {
                                    const syntheticEvent = {
                                        target: { value: newValue?.id || '' },
                                    } as React.ChangeEvent<HTMLInputElement>;
                                    handleSourceLocation(syntheticEvent);
                                }}
                                style={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Location"
                                        variant="outlined"
                                        sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                        error={touched[locationSourceFields.inventoryLocation.name] && Boolean(errors[locationSourceFields.inventoryLocation.name])}
                                        helperText={
                                            touched[locationSourceFields.inventoryLocation.name] && errors[locationSourceFields.inventoryLocation.name]
                                                ? errors[locationSourceFields.inventoryLocation.name]
                                                : ''
                                        }
                                    />
                                )}
                            />




                        </>
                    )}

                    {values.sourceLocationType === 'supplier' && (
                        <TextField
                            label="Enter Purchase Order Number"
                            variant="outlined"
                            fullWidth
                            value={purchaseOrderNumber}
                            onChange={(e: any) => {
                                setPurchaseOrderNumber(e.target.value)
                            }}
                        />
                    )}

                    {values.sourceLocationType === 'supplier' && (
                        <TextField
                            {...title}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            fullWidth
                            value={values.title}

                        />


                    )}

                    {values.sourceLocationType === 'supplier' && (
                        <Select
                            value={values.isService ? 'true' : 'false'}
                            onChange={(event) => {
                                const isServiceBool = event.target.value === 'true';
                                setFieldValue('isService', isServiceBool);
                            }}
                        >
                            <MenuItem value="true">Service</MenuItem>
                            <MenuItem value="false">Product</MenuItem>
                        </Select>
                    )}

                    {values.sourceLocationType === 'supplier' && (
                        <TextField
                            {...grvNumber}

                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={(event: any) => {
                                setFieldValue('grvNumber', event.target.value);
                            }}

                        />


                    )}

                    <Field {...locationSourceFields.supplierId} type="hidden" />
                    <Field {...locationSourceFields.supplierName} type="hidden" />


                </Stack>

            </Card>


            <Card variant="outlined" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                    Transfer To (Destination Location)
                </Typography>
                <Divider style={{ marginBottom: '15px' }} />


                <Stack spacing={2}>

                    <TextField
                        select
                        fullWidth
                        {...destinationLocationType}
                        value={values.destinationLocationType}
                        autoComplete="on"
                        onChange={(event: any) => {
                            setFieldValue('destinationLocationType', event.target.value);
                        }}
                    >
                        <MenuItem value="unit">Unit</MenuItem>
                        <MenuItem value="inventoryLocation">Inventory Location</MenuItem>
                        <MenuItem value="supplier">Supplier</MenuItem>
                    </TextField>

                    {values.destinationLocationType === 'unit' && (
                        <>
                            <TextField
                                {...locationDestinationFields.development}
                                select
                                value={selectedDestinationDevelopment}
                                onChange={handleDestinationDevelopmentChange}
                                fullWidth
                            >
                                {developments.map((dev) => (
                                    <MenuItem key={dev.id} value={dev.id}>
                                        {dev.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                {...locationDestinationFields.project}
                                select
                                value={selectedDestinationProject}
                                onChange={handleDestinationProjectChange}
                                fullWidth
                                disabled={!getProjects(selectedDestinationDevelopment).length}
                            >
                                {getProjects(selectedDestinationDevelopment).map((proj) => (
                                    <MenuItem key={proj.id} value={proj.id}>
                                        {proj.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <Autocomplete
                                options={destinationUnits || []}
                                getOptionLabel={(option) => option.unitName}
                                value={destinationUnits.find((unit) => unit.id === selectedDestinationUnit) || null}
                                onChange={(event, newValue) => {
                                    const syntheticEvent = {
                                        target: { value: newValue?.id || '' },
                                    } as React.ChangeEvent<HTMLInputElement>;
                                    handleDestinationUnitChange(syntheticEvent);
                                }}
                                disabled={!destinationUnits.length}
                                style={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Destination Unit"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                        </>
                    )}

                    {values.destinationLocationType === 'inventoryLocation' && (
                        <>

                            <>
                                <Autocomplete
                                    options={inventoryLocations || []}
                                    getOptionLabel={(option) => `${option.locationCode}, ${option.description}`}
                                    value={inventoryLocations?.find((loc) => loc.id === selectedDestinationLocation) || null}
                                    onChange={(event, newValue) => {
                                        const syntheticEvent = {
                                            target: { value: newValue?.id || '' },
                                        } as React.ChangeEvent<HTMLInputElement>;
                                        handleDestinationLocation(syntheticEvent);
                                    }}
                                    style={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Destination Location"
                                            variant="outlined"
                                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                            error={
                                                touched[locationDestinationFields.inventoryLocation?.name] &&
                                                Boolean(errors[locationDestinationFields.inventoryLocation?.name])
                                            }
                                            helperText={
                                                touched[locationDestinationFields.inventoryLocation?.name] &&
                                                    errors[locationDestinationFields.inventoryLocation?.name]
                                                    ? errors[locationDestinationFields.inventoryLocation?.name]
                                                    : ''
                                            }
                                        />
                                    )}
                                />

                            </>

                        </>
                    )}

                    {values.destinationLocationType === 'supplier' && (

                        <TextField
                            label="Enter Purchase Order Number"
                            variant="outlined"
                            fullWidth
                            value={purchaseOrderNumber}
                            onChange={(e) => {
                                setPurchaseOrderNumber(e.target.value)

                            }}
                        />

                    )}
                    <Field {...locationDestinationFields.supplierId} type="hidden" />
                    <Field {...locationDestinationFields.supplierName} type="hidden" />

                </Stack>
            </Card>

            <Card variant="outlined" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                    Transfer Additional Information
                </Typography>
                <Divider style={{ marginBottom: '15px' }} />


                <Stack spacing={2}>

                    <TextField
                        {...transferredDate}

                        type="date"
                        InputLabelProps={{ shrink: true }}

                        fullWidth

                        // value={values.transferredDate}
                        onChange={(event) => {
                            const dateString = event.target.value;
                            const dateObj = new Date(dateString); 
                            setFieldValue('transferredDate', dateObj); 
                          }}
                    />

                    <TextField
                        {...reasonForTransfer}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        fullWidth
                        onChange={(event: any) => {
                            setFieldValue('reasonForTransfer', event.target.value);
                        }}

                    />

                </Stack>
            </Card>


            <Field {...totalVAT} type="hidden" />
            <Field {...totalb4VAT} type="hidden" />
            <Field {...grandTotal} type="hidden" />
            <Field {...totalDiscount} type="hidden" />
            <Field {...procurementProcessId} type="hidden" />

            <FieldArray name="items">
                {({ push: pushItem, remove: removeItem }) => (
                    <Stack spacing={2} mt={3}>
                        {values.items.map((item: any, iIndex: number) => (
                            <Stack direction="row" spacing={2} alignItems="center" key={iIndex}>

                                <TextField
                                    {...itemFields.description}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={item.description}
                                    onChange={(e) => setFieldValue(`items[${iIndex}].description`, e.target.value)}
                                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />


                                <TextField
                                    {...itemFields.quantity.quantityValue}
                                    type="number"
                                    value={item.quantity.quantityValue}
                                    onChange={(e) => {
                                        const quantityValue = parseFloat(e.target.value) || 0;
                                        const baseCost = (item.rate - item.discountPerItem) * quantityValue;
                                        const VAT = item.VAT ? 0.15 * baseCost : 0;
                                        const totalCost = baseCost + VAT;

                                        setFieldValue(`items[${iIndex}].quantity.quantityValue`, quantityValue);
                                        setFieldValue(`items[${iIndex}].totalCostPerItem`, roundToTwoDecimals(totalCost));
                                    }}
                                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />

                                <TextField
                                    {...itemFields.quantity.quantityUnit}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={item.quantity.quantityUnit || ''}
                                    onChange={(e) => setFieldValue(`items[${iIndex}].quantity.quantityUnit`, e.target.value)}
                                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />



                                <TextField
                                    {...itemFields.rate}
                                    InputProps={{
                                        readOnly: true,
                                    }}

                                    value={item.rate}
                                    onChange={(e) => {
                                        const rate = parseFloat(e.target.value) || 0;
                                        const baseCost = (rate - item.discountPerItem) * item.quantity.quantityValue;
                                        const VAT = item.VAT ? 0.15 * baseCost : 0;
                                        const totalCost = baseCost + VAT;

                                        setFieldValue(`items[${iIndex}].rate`, rate);
                                        setFieldValue(`items[${iIndex}].totalCostPerItem`, roundToTwoDecimals(totalCost));
                                    }}
                                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />


                                <TextField
                                    {...itemFields.discountPerItem}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    // type="number"
                                    value={item.discountPerItem}
                                    onChange={(e) => {
                                        const discountPerItem = parseFloat(e.target.value) || 0;
                                        const baseCost = (item.rate - discountPerItem) * item.quantity.quantityValue;
                                        const VAT = item.VAT ? 0.15 * baseCost : 0;
                                        const totalCost = baseCost + VAT;

                                        setFieldValue(`items[${iIndex}].discountPerItem`, discountPerItem);
                                        setFieldValue(`items[${iIndex}].totalCostPerItem`, roundToTwoDecimals(totalCost));
                                    }}
                                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />


                                <Switch
                                    checked={item.VAT || false}
                                    onChange={(e) => {
                                        const VATEnabled = e.target.checked;
                                        const baseCost = (item.rate - item.discountPerItem) * item.quantity.quantityValue;
                                        const VAT = VATEnabled ? 0.15 * baseCost : 0;
                                        const totalCost = baseCost + VAT;

                                        setFieldValue(`items[${iIndex}].VAT`, VATEnabled);
                                        setFieldValue(`items[${iIndex}].totalCostPerItem`, roundToTwoDecimals(totalCost));
                                    }}
                                />
                                <Typography>VAT: {item.VAT ? "Yes (15%)" : "No"}</Typography>


                                <TextField
                                    name={`items[${iIndex}].totalCostPerItem`}
                                    label="Total Amount"
                                    value={formatCurrency(item.totalCostPerItem || 0, values.currency || 'usd')}
                                    disabled
                                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />


                                <IconButton color="error" onClick={() => removeItem(iIndex)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        ))}


                        {/* <Button
                            onClick={() =>
                                pushItem({
                                    description: '',
                                    quantity: { quantityValue: 0, quantityUnit: '' },
                                    rate: 0,
                                    discountPerItem: 0,
                                    totalCostPerItem: 0,
                                    VAT: false,
                                    unit: { id: '', name: '' },
                                    procured: false,
                                })
                            }
                            variant="contained"
                            size="small"
                        >
                            Add Item
                        </Button> */}
                    </Stack>
                )}
            </FieldArray>

            <Card sx={{ padding: '15px', backgroundColor: '#d1e7dd', textAlign: 'right', borderRadius: 2 }}>
                <Stack direction="column" spacing={1}>



                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle2" sx={{ color: '#333', fontWeight: 'bold' }}>
                            Total (Excl. VAT):
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: '#333' }}>
                            {values.items
                                ? formatCurrency(
                                    calculateTotalWithDiscount(
                                        values.items,
                                        values.totalDiscount || 0
                                    ) -
                                    values.items.reduce((acc: number, item: any) => {
                                        const VAT = item.VAT ? 0.15 * (item.rate - item.discountPerItem) * item.quantity.quantityValue : 0;
                                        return acc + VAT;
                                    }, 0),
                                    values.currency || 'usd'
                                )
                                : formatCurrency(0, 'usd')
                            }
                        </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle2" sx={{ color: '#333', fontWeight: 'bold' }}>
                            VAT(15%):
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: '#333' }}>
                            {values.totalVAT
                                ? formatCurrency(
                                    values.totalVAT,
                                    values.currency || 'usd'
                                )
                                : formatCurrency(0, 'usd')
                            }
                        </Typography>
                    </Box>


                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle2" sx={{ color: '#333', fontWeight: 'bold' }}>
                            Grand Total:
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: '#333' }}>
                            {values.items
                                ? formatCurrency(
                                    calculateTotalWithDiscount(
                                        values.items,
                                        values.discount || 0
                                    ),
                                    values.currency || 'usd'
                                )
                                : formatCurrency(0, 'usd')
                            }
                        </Typography>
                    </Box>
                </Stack>
            </Card>

        </Stack>
    );
};

export default CreateItemTransferForm;