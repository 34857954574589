import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import { useGetUsersQuery } from '../../../redux/vmsApi';

interface RequisitionsFilterProps {
    filters: any;
    onFilterChange: (field: string, value: any) => void;
    onApplyFilters: () => void;
}

const RequisitionsFilter: React.FC<RequisitionsFilterProps> = ({ filters, onFilterChange, onApplyFilters }) => {
    const [reportByEmailEnabled, setReportByEmailEnabled] = useState<boolean>(false);

    const handleReportByEmailToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isEnabled = event.target.checked;
        setReportByEmailEnabled(isEnabled);
        onFilterChange('reportByEmail', isEnabled);


        if (!isEnabled) {
            onFilterChange('reportName', '');
        }
    };

    const handleApplyFilters = () => {

        onApplyFilters();


        setReportByEmailEnabled(false);
        onFilterChange('reportByEmail', false);
        onFilterChange('reportName', '');
    };

    const [procurementClerks, setProcurementClerks] = useState<{ name: string }[]>([]);


    const { data: users, refetch } = useGetUsersQuery();
    useEffect(() => { 
        if (users) {
            
            const clerks = users
                .filter((user: any) => Array.isArray(user.roles) && user.roles.includes('procurement-clerk'))
                .map((user: any) => ({ name: user.name }));
            setProcurementClerks(clerks);
        }
    }, [users]);
    

    return (
        <Box
            component="div"
            sx={{
                padding: 3,
                borderRadius: 2,
                marginBottom: 3,
                backgroundColor: '#f9f9f9',
                boxShadow: 3,
            }}
        >
            <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                Filters
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={2} alignItems="center">
                <TextField
                    label="Title"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    value={filters.title}
                    onChange={(e) => onFilterChange('title', e.target.value)}
                />
                <TextField
                    label="Requisition Number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    value={filters.reqNumber}
                    onChange={(e) => onFilterChange('reqNumber', e.target.value)}
                />
                <TextField
                    label="Development"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    value={filters.development}
                    onChange={(e) => onFilterChange('development', e.target.value)}
                />


                <TextField
                    label="From Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                    sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    value={filters.fromDate}
                    onChange={(e) => onFilterChange('fromDate', e.target.value)}
                />
                <TextField
                    label="To Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                    sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    value={filters.toDate}
                    onChange={(e) => onFilterChange('toDate', e.target.value)}
                />
                <FormControl variant="outlined" size="small" sx={{ minWidth: 240 }}>
                    <InputLabel>Current Task</InputLabel>
                    <Select
                        value={filters.currentTask}
                        onChange={(e) => onFilterChange('currentTask', e.target.value)}
                        label="Current Task"
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="SiteManagerReviewPurchaseRequisition">Site Manager Review</MenuItem>
                        <MenuItem value="CorrectPurchaseRequisition">Correct Requisition</MenuItem>
                        <MenuItem value="ProjectManagerReviewPurchaseRequisition">Project Manager Review</MenuItem>
                        <MenuItem value="PCToUploadQuotations">Upload Quotations</MenuItem>
                        <MenuItem value="CPOPicksSupplier">Pick Supplier</MenuItem>
                        <MenuItem value="ProChairReviewsSupplierChoice">Review Supplier Choice</MenuItem>
                        <MenuItem value="CFOAllocateFunds">CFO Allocate Funds</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 240 }}>
                    <InputLabel>Rejected By</InputLabel>
                    <Select
                        value={filters.rejectedBy}
                        onChange={(e) => onFilterChange('rejectedBy', e.target.value)}
                        label="Rejected By"
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="siteManagerReview">Site Manager</MenuItem>
                        <MenuItem value="ProjectManagerReview">Project Manager</MenuItem>
                        <MenuItem value="pcReview">Procurement Officer</MenuItem>
                        <MenuItem value="cpoReview">CPO</MenuItem>
                        <MenuItem value="proChairReview">Pro Chair</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 240 }}>
                    <InputLabel>Approved By</InputLabel>
                    <Select
                        value={filters.approvedBy}
                        onChange={(e) => onFilterChange('approvedBy', e.target.value)}
                        label="Approved By"
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="siteManagerReview">Site Manager</MenuItem>
                        <MenuItem value="ProjectManagerReview">Project Manager</MenuItem>
                        <MenuItem value="pcReview">Procurement Officer</MenuItem>
                        <MenuItem value="cpoReview">CPO</MenuItem>
                        <MenuItem value="proChairReview">Pro Chair</MenuItem>
                    </Select>
                </FormControl>

                <FormControl variant="outlined" size="small" sx={{ minWidth: 240 }}>
                    <InputLabel>Procurement Officer</InputLabel>
                    <Select
                        value={filters.procurementOfficer || ''}
                        onChange={(e) => onFilterChange('procurementOfficer', e.target.value)}
                        label="Procurement Officer"
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    >
                        <MenuItem value="all">All</MenuItem>
                        {procurementClerks.map((clerk) => (
                            <MenuItem key={clerk.name} value={clerk.name}>
                                {clerk.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


                <FormControlLabel
                    control={
                        <Switch
                            checked={reportByEmailEnabled}
                            onChange={handleReportByEmailToggle}
                            color="primary"
                        />
                    }
                    label="Generate & Email Filter Report"
                    sx={{ marginLeft: 1 }}
                />


                {reportByEmailEnabled && (
                    <TextField
                        label="Report Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.reportName || ''}
                        onChange={(e) => onFilterChange('reportName', e.target.value)}
                    />
                )}
            </Box>
            <Stack direction="row" spacing={2} mt={2}>
                <Button
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={handleApplyFilters}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 'bold',
                        color: '#1976d2',
                        borderColor: '#1976d2',
                        '&:hover': {
                            backgroundColor: '#e3f2fd',
                            borderColor: '#1565c0',
                        },
                    }}
                >
                    Apply Filters
                </Button>
            </Stack>
        </Box>
    );
};

export default RequisitionsFilter;
