const local = {
  API_BASE_URL: 'http://localhost:3000/api',
  STAGE: 'dev',
  logrocket: true
}
const prod = {
  API_BASE_URL: 'https://api.vms.westprop.com/api',
  STAGE: 'prod',
  logrocket: true
}
const stage_env = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'local':
      return local;
    case 'prod':
      return prod;
    default:
      return local;
  }
}
const config = {
  CDN_URL: 'https://assets.westprop.com',
  whiteLogo: 'https://assets.westprop.com/WestProp-Logo-White.png',
  blueLogo: 'https://assets.westprop.com/WestProp-Logo-Blue.png',
  ...(stage_env())
}

export default config;
