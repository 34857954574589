import React from 'react';
import {Button, Container, Stack, Typography} from "@mui/material";
import authSlice from "../../redux/reducers/authSlice";
import {styled} from "@mui/material/styles";
import {useAppDispatch} from "../../redux/hooks";
import config from "../../config";

const FullPageContainer = styled(Container)({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
});

const Logo = styled('img')({
  width: '150px',
  marginBottom: '20px'
});

const LoggedOutView: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <FullPageContainer>
      <Logo src={config.blueLogo} alt="WestProp Logo"/>
      <Typography variant="h4" gutterBottom>
        WestProp Operations Hub
      </Typography>
      <Stack spacing={2} direction={'row'}>
        <Button variant="contained" color="primary" onClick={() =>
        {
          dispatch(authSlice.actions.setAuthState('login'))
          dispatch(authSlice.actions.openLoginModal())
        }}
        >
          Login
        </Button>
        <Button variant="contained" color="primary" onClick={() =>
          {
            dispatch(authSlice.actions.setAuthState('create'))
            dispatch(authSlice.actions.openLoginModal())
          }}
        >
          Sign Up
        </Button>
      </Stack>

    </FullPageContainer>
  )
}

export default LoggedOutView;