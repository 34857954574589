import React from 'react';
import { MenuItem, Stack, TextField, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';

import itemFormModel from '../models/createItemModel';
import { useGetInventoriesQuery, useGetItemCategoriesQuery } from '../../../redux/vmsApi';

const {
    formField: {
        itemCode,
        quantityUnit,
        description,
        categoryId,
        itemCost,
        inventoryId
    },
} = itemFormModel;

interface OwnProps {
    editing?: boolean;
}

const CreateItemForm: React.FC<OwnProps> = (props) => {

    const { data: itemCategories = [] } = useGetItemCategoriesQuery({});
    const { data: inventories = [] } = useGetInventoriesQuery({});
    const { editing = false } = props;
    const { errors, touched, values, setFieldValue } = useFormikContext<any>();

    const unitOptions = [
        { value: 'm' },
        { value: 'm²' },
        { value: 'm³' },
        { value: 'kg' },
        { value: 'buckets' },
        { value: 'item'},
        { value: 'unit'},
        { value: 'length'},
        { value: 'bags' },
        { value: 'each' },
        { value: 'pack' },
        { value: 'box' },
        { value: 'litres' },
        { value: 'Tonnes' },
        { value: 'rolls' },
        { value: 'pairs' },
      ];

    return (
        <Stack width="100%" spacing={5}>
            <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
                {editing ? 'Edit' : 'Add'} Item
            </Typography>
            <Stack direction={'row'} spacing={2}>
                <Field
                    as={TextField}
                    fullWidth
                    {...itemCode}
                    error={touched[itemCode.name] && Boolean(errors[itemCode.name])}
                    helperText={touched[itemCode.name] && errors[itemCode.name] ? errors[itemCode.name] : ''}
                />
                 
                 <Field
                    as={TextField}
                    fullWidth
                    select

                    {...inventoryId}
                    value={values.inventoryId || ''}
                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                    error={touched[inventoryId.name] && Boolean(errors[inventoryId.name])}
                    helperText={touched[inventoryId.name] && errors[inventoryId.name] ? errors[inventoryId.name] : ''}
                >
                    {inventories.map((inventory) => (
                        <MenuItem key={inventory.id} value={inventory.id}>
                            {inventory.inventoryCode}( {inventory.description})
                        </MenuItem>
                    ))}
                </Field>
                

                <Field
                    as={TextField}
                    fullWidth
                    select
                    {...quantityUnit}
                    value={values.quantityUnit || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`quantityUnit`, e.target.value)
                    }

                    error={touched[`quantityUnit.name`] && Boolean(errors[`quantityUnit.name`])}
                    helperText={touched[`quantityUnit.name`] && errors[`quantityUnit.name`] ? errors[`quantityUnit.name`] : ''}
                    sx={{ minWidth: '100px' }}
                >
                    {unitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.value}
                        </MenuItem>
                    ))}
                </Field>

                <Field
                    as={TextField}
                    fullWidth
                    {...itemCost}
                    type="number"
                    error={touched[itemCost.name] && Boolean(errors[itemCost.name])}
                    helperText={touched[itemCost.name] && errors[itemCost.name] ? errors[itemCost.name] : ''}
                />

            </Stack>

            <Stack direction={'row'} spacing={2}>

                <Field
                    as={TextField}
                    fullWidth
                    {...description}
                    error={touched[description.name] && Boolean(errors[description.name])}
                    helperText={touched[description.name] && errors[description.name] ? errors[description.name] : ''}
                />

                <Field
                    as={TextField}
                    fullWidth
                    select

                    {...categoryId}
                    value={values.categoryId || ''}
                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                    error={touched[categoryId.name] && Boolean(errors[categoryId.name])}
                    helperText={touched[categoryId.name] && errors[categoryId.name] ? errors[categoryId.name] : ''}
                >
                    {itemCategories.map((itemCategory) => (
                        <MenuItem key={itemCategory.id} value={itemCategory.id}>
                            {itemCategory.categoryCode}( {itemCategory.description})
                        </MenuItem>
                    ))}
                </Field>

            </Stack>


        </Stack>
    );
};

export default CreateItemForm;
